var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "grid grid-cols-6 gap-6 gap-y-8 my-8" },
    [
      _c(
        "div",
        { staticClass: "col-span-3" },
        [
          _c("fl-labeled-input", {
            attrs: {
              fieldId: "film_name",
              rules: { required: true },
              label: "作品名",
            },
            model: {
              value: _vm.value.translations.ja.film_name,
              callback: function ($$v) {
                _vm.$set(_vm.value.translations.ja, "film_name", $$v)
              },
              expression: "value.translations.ja.film_name",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-span-3" },
        [
          _c("fl-labeled-input", {
            attrs: {
              fieldId: "film_name_en",
              rules: { required: true, max: 255, english: true },
              label: "作品名",
              sublabel: "英語",
            },
            model: {
              value: _vm.value.translations.en.film_name,
              callback: function ($$v) {
                _vm.$set(_vm.value.translations.en, "film_name", $$v)
              },
              expression: "value.translations.en.film_name",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-span-6 col-start-1" },
        [
          _c("fl-labeled-input", {
            attrs: {
              fieldId: "film_name_yomi",
              rules: {
                required: _vm.isRequired,
                regex: /^[ァ-ンヴー0-9０-９]+$/,
                max: 255,
              },
              label: "フリガナ",
              explanation: "読み仮名をカタカナだけで入力してください。",
            },
            model: {
              value: _vm.value.film_name_yomi,
              callback: function ($$v) {
                _vm.$set(_vm.value, "film_name_yomi", $$v)
              },
              expression: "value.film_name_yomi",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-span-6 col-start-1" },
        [
          _c("fl-labeled-input", {
            attrs: {
              fieldId: "original_name",
              rules: { required: _vm.isRequired },
              explanation: "半角英数字のみで入力してください。",
              label: "日本語作品名のローマ字表記",
            },
            model: {
              value: _vm.value.original_name,
              callback: function ($$v) {
                _vm.$set(_vm.value, "original_name", $$v)
              },
              expression: "value.original_name",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-span-3 col-start-1" },
        [
          _c("fl-select", {
            attrs: {
              fieldId: "film_type",
              options: _vm.filmType,
              required: _vm.isRequired,
              label: "動画区分(選択)",
              unselected: true,
            },
            model: {
              value: _vm.value.film_type,
              callback: function ($$v) {
                _vm.$set(_vm.value, "film_type", $$v)
              },
              expression: "value.film_type",
            },
          }),
        ],
        1
      ),
      _vm.value.film_type === 1
        ? _c("p", { staticClass: "col-span-3 text-xs my-auto text-left" }, [
            _c("span", [
              _vm._v(
                "TV番組の登録は、1シーズン毎に1作品としてご登録ください。詳細は、ヘッダーの【使い方】の中の「よくある質問」などをご参照ください"
              ),
            ]),
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "col-span-6 col-start-1" },
        [
          _c("fl-labeled-input", {
            attrs: {
              type: "number",
              fieldId: "play_time",
              rules: { required: _vm.isRequired, integer: true, max: 4 },
              label: "上映時間",
              explanation:
                "分数を、数字のみで入力ください。TVシリーズの場合には1話分の分数をご記入ください。各話で分数に差異がある場合には、シリーズ内でもっともスタンダードな分数をご記入ください。",
            },
            on: { input: _vm.calcFormatFromPlayTime },
            model: {
              value: _vm.value.play_time,
              callback: function ($$v) {
                _vm.$set(_vm.value, "play_time", _vm._n($$v))
              },
              expression: "value.play_time",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-span-4 col-start-1" },
        [
          _c("fl-select-lanugages", {
            attrs: {
              isRequired: _vm.isRequired,
              options: _vm.subtitleLanguage,
            },
            model: {
              value: _vm.value.main_video,
              callback: function ($$v) {
                _vm.$set(_vm.value, "main_video", $$v)
              },
              expression: "value.main_video",
            },
          }),
        ],
        1
      ),
      _vm._m(0),
      _c(
        "div",
        { staticClass: "col-span-3 col-start-1" },
        [
          _c("fl-select", {
            attrs: {
              fieldId: "production_country",
              options: _vm.productionCountry,
              required: _vm.isRequired,
              label: "製作国",
              unselected: true,
            },
            model: {
              value: _vm.value.production_country,
              callback: function ($$v) {
                _vm.$set(_vm.value, "production_country", $$v)
              },
              expression: "value.production_country",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-span-6 col-start-1" },
        [
          _c("fl-labeled-input", {
            attrs: {
              fieldId: "production_year",
              rules: { required: _vm.isRequired, max: 4 },
              type: "number",
              label: "公開年",
              explanation: "半角英数字のみで入力してください。",
            },
            model: {
              value: _vm.value.production_year,
              callback: function ($$v) {
                _vm.$set(_vm.value, "production_year", $$v)
              },
              expression: "value.production_year",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-span-3 col-start-1" },
        [
          _c("fl-select", {
            attrs: {
              fieldId: "language",
              options: _vm.language,
              required: _vm.isRequired,
              label: "音声言語",
              unselected: true,
            },
            model: {
              value: _vm.value.language,
              callback: function ($$v) {
                _vm.$set(_vm.value, "language", $$v)
              },
              expression: "value.language",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-span-6" },
        [
          _c("fl-url-input", {
            attrs: {
              vid: "trailer_url",
              type: "text",
              label: "予告編動画",
              explanation: "Youtubeのリンク及びVimeoリンクに対応しています。",
              rules: { required: _vm.isRequired, url: true },
            },
            model: {
              value: _vm.value.trailer_url,
              callback: function ($$v) {
                _vm.$set(_vm.value, "trailer_url", $$v)
              },
              expression: "value.trailer_url",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-span-3 col-start-1" },
        [
          _c("fl-select", {
            attrs: {
              fieldId: "type",
              options: _vm.genreType,
              required: _vm.isRequired,
              label: "種類",
              unselected: true,
            },
            model: {
              value: _vm.value.type,
              callback: function ($$v) {
                _vm.$set(_vm.value, "type", $$v)
              },
              expression: "value.type",
            },
          }),
        ],
        1
      ),
      _c(
        "label",
        { staticClass: "col-start-1 col-span-1 mt-3 text-right text-sm" },
        [_vm._v("ジャンル")]
      ),
      _c(
        "div",
        { staticClass: "col-span-5 relative" },
        [
          _c("InputGenreField", {
            staticClass: "w-full",
            attrs: { is_main: true },
            model: {
              value: _vm.value.genres,
              callback: function ($$v) {
                _vm.$set(_vm.value, "genres", $$v)
              },
              expression: "value.genres",
            },
          }),
          _c("ValidateParts", {
            attrs: {
              value: _vm.mainGenres,
              vid: "mainGenres",
              rules: { required: _vm.isRequired },
              name: "ジャンル",
              errorDisplaySize: "text-xxs",
            },
          }),
        ],
        1
      ),
      _c(
        "label",
        { staticClass: "col-start-1 col-span-1 mt-3 text-right text-sm" },
        [_vm._v("サブジャンル")]
      ),
      _c(
        "div",
        { staticClass: "col-span-5 relative" },
        [
          _c("InputGenreField", {
            staticClass: "w-full",
            attrs: { is_main: false },
            model: {
              value: _vm.value.genres,
              callback: function ($$v) {
                _vm.$set(_vm.value, "genres", $$v)
              },
              expression: "value.genres",
            },
          }),
          _c("ValidateParts", {
            attrs: {
              value: _vm.subGenres,
              vid: "subGenres",
              rules: { required: _vm.isRequired },
              name: "サブジャンル",
              errorDisplaySize: "text-xxs",
            },
          }),
        ],
        1
      ),
      _vm.value.film_type === 1
        ? [
            _c(
              "div",
              { staticClass: "col-start-1 col-span-3" },
              [
                _c("fl-labeled-input", {
                  attrs: {
                    rules: { max: 100 },
                    fieldId: "season_name",
                    label: "シーズン名",
                    sublabel: "日本語",
                  },
                  model: {
                    value: _vm.value.translations.ja.season_name,
                    callback: function ($$v) {
                      _vm.$set(_vm.value.translations.ja, "season_name", $$v)
                    },
                    expression: "value.translations.ja.season_name",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-span-3" },
              [
                _c("fl-labeled-input", {
                  attrs: {
                    rules: { max: 100 },
                    fieldId: "season_name",
                    label: "シーズン名",
                    sublabel: "英語",
                  },
                  model: {
                    value: _vm.value.translations.en.season_name,
                    callback: function ($$v) {
                      _vm.$set(_vm.value.translations.en, "season_name", $$v)
                    },
                    expression: "value.translations.en.season_name",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-start-1 col-span-3" },
              [
                _c("fl-labeled-input", {
                  attrs: {
                    label: "総話数",
                    type: "number",
                    rules: { max: 4 },
                    fieldId: "episode_number",
                  },
                  model: {
                    value: _vm.value.episode_number,
                    callback: function ($$v) {
                      _vm.$set(_vm.value, "episode_number", $$v)
                    },
                    expression: "value.episode_number",
                  },
                }),
              ],
              1
            ),
          ]
        : _vm._e(),
      _c(
        "div",
        { staticClass: "col-start-1 col-span-3" },
        [
          _c("fl-select", {
            attrs: {
              label: "レイティング(映倫)",
              options: _vm.rating,
              fieldId: "rating",
              unselected: true,
              required: _vm.isRequired,
            },
            model: {
              value: _vm.value.rating,
              callback: function ($$v) {
                _vm.$set(_vm.value, "rating", $$v)
              },
              expression: "value.rating",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-span-3 col-start-1" },
        [
          _c("fl-labeled-input", {
            attrs: {
              fieldId: "copyright",
              rules: { required: _vm.isRequired },
              label: "コピーライト(著作権表示)",
              sublabel: "日本語(150文字以内)",
            },
            model: {
              value: _vm.value.translations.ja.copyright,
              callback: function ($$v) {
                _vm.$set(_vm.value.translations.ja, "copyright", $$v)
              },
              expression: "value.translations.ja.copyright",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-span-3" },
        [
          _c("fl-labeled-input", {
            attrs: {
              fieldId: "copyright",
              rules: { required: false },
              label: "コピーライト(著作権表示)",
              sublabel: "英語(150文字以内)",
            },
            model: {
              value: _vm.value.translations.en.copyright,
              callback: function ($$v) {
                _vm.$set(_vm.value.translations.en, "copyright", $$v)
              },
              expression: "value.translations.en.copyright",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-span-3 col-start-1" },
        [
          _c("fl-labeled-input", {
            attrs: {
              fieldId: "director",
              rules: { required: _vm.isRequired, max: 255 },
              label: "監督・演出",
              sublabel: "日本語(50文字以内)",
            },
            model: {
              value: _vm.value.translations.ja.director,
              callback: function ($$v) {
                _vm.$set(_vm.value.translations.ja, "director", $$v)
              },
              expression: "value.translations.ja.director",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-span-3" },
        [
          _c("fl-labeled-input", {
            attrs: {
              fieldId: "director_en",
              rules: { required: _vm.isRequired, max: 255, english: true },
              label: "監督・演出",
              sublabel: "英語(50文字以内)",
            },
            model: {
              value: _vm.value.translations.en.director,
              callback: function ($$v) {
                _vm.$set(_vm.value.translations.en, "director", $$v)
              },
              expression: "value.translations.en.director",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-span-3 col-start-1" },
        [
          _c("fl-labeled-input", {
            attrs: {
              fieldId: "main_cast_1",
              rules: { required: _vm.isRequired, max: 50 },
              label: "主要キャスト1",
              sublabel: "日本語(50文字以内)",
            },
            model: {
              value: _vm.value.translations.ja.main_cast_1,
              callback: function ($$v) {
                _vm.$set(_vm.value.translations.ja, "main_cast_1", $$v)
              },
              expression: "value.translations.ja.main_cast_1",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-span-3" },
        [
          _c("fl-labeled-input", {
            attrs: {
              fieldId: "main_cast_1_en",
              rules: { required: _vm.isRequired, max: 50, english: true },
              label: "主要キャスト1",
              sublabel: "英語(50文字以内)",
            },
            model: {
              value: _vm.value.translations.en.main_cast_1,
              callback: function ($$v) {
                _vm.$set(_vm.value.translations.en, "main_cast_1", $$v)
              },
              expression: "value.translations.en.main_cast_1",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-span-3 col-start-1" },
        [
          _c("fl-url-input", {
            attrs: {
              fieldId: "screener_url",
              rules: { required: _vm.isRequired, url: true },
              label: "本編スクリーナー",
            },
            model: {
              value: _vm.value.screener_url,
              callback: function ($$v) {
                _vm.$set(_vm.value, "screener_url", $$v)
              },
              expression: "value.screener_url",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-span-3" },
        [
          _c("fl-labeled-input", {
            attrs: {
              fieldId: "screener_password",
              rules: { max: 255 },
              labels: ["本編スクリーナー", "パスワード"],
              sublabel: "任意",
            },
            model: {
              value: _vm.value.screener_password,
              callback: function ($$v) {
                _vm.$set(_vm.value, "screener_password", $$v)
              },
              expression: "value.screener_password",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-span-6" },
        [
          _c("fl-translatable-textarea", {
            attrs: {
              fieldId: "outline",
              rules: { required: _vm.isRequired, max: 1000 },
              rules2: { required: _vm.isRequired, max: 4000 },
              label: "あらすじ",
              label2:
                "(日本語1000文字、英語4000文字まで)英文の場合、半角英数で1500文字以上を推奨（IMBb基準）",
            },
            model: {
              value: _vm.value.translations,
              callback: function ($$v) {
                _vm.$set(_vm.value, "translations", $$v)
              },
              expression: "value.translations",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-span-6" },
        [
          _c("fl-translatable-textarea", {
            attrs: {
              fieldId: "pr_message",
              rules: { required: _vm.isRequired, max: 1000 },
              rules2: { required: _vm.isRequired, max: 4000 },
              label: "PRメッセージ",
              label2: "(日本語1000文字、英語4000文字まで)",
            },
            model: {
              value: _vm.value.translations,
              callback: function ($$v) {
                _vm.$set(_vm.value, "translations", $$v)
              },
              expression: "value.translations",
            },
          }),
        ],
        1
      ),
      _c(
        "label",
        { staticClass: "col-start-1 col-span-1 text-right text-sm" },
        [_vm._v("メインビジュアル")]
      ),
      _c(
        "div",
        { staticClass: "col-span-2" },
        [
          _c(
            "div",
            {
              staticClass:
                "w-full h-full mr-6 border-dashed border-2 border-very-light-grey flex",
              class: [_vm.isDrag ? "bg-gray-200" : "bg-white"],
              on: {
                dragover: function ($event) {
                  $event.preventDefault()
                  return _vm.checkDrag($event, true)
                },
                dragleave: function ($event) {
                  $event.preventDefault()
                  return _vm.checkDrag($event, false)
                },
                drop: function ($event) {
                  $event.preventDefault()
                  return _vm.inputFile($event, "drop", "featured_images")
                },
              },
            },
            [
              _c("div", { staticClass: "w-full my-4 mx-auto text-center" }, [
                _vm._m(1),
                _c(
                  "button",
                  {
                    staticClass:
                      "px-6 py-3 mb-2 mx-auto text-sm font-semibold border border-brown-grey",
                    on: {
                      click: function ($event) {
                        return _vm.$refs.file.click()
                      },
                    },
                  },
                  [_vm._v(" ファイルを選択 ")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "ml-2 text-xxs font-semibold tracking-widest",
                  },
                  [_vm._v("ファイルをドラッグするか選択してください。")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "ml-2 text-xxs font-semibold text-brown-grey",
                  },
                  [_vm._v("最大アップロードサイズ: 40MB")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "ml-2 text-xxs font-semibold text-brown-grey",
                  },
                  [
                    _vm._v(
                      " ファイル名には半角英数字テキストのみご使用ください。 "
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "ml-2 text-xxs font-semibold text-brown-grey",
                  },
                  [_vm._v("ファイル形式は、jpg/pngでお願いします。")]
                ),
                _c("input", {
                  ref: "file",
                  staticClass: "hidden",
                  attrs: { type: "file", accept: "image/*" },
                  on: {
                    change: function ($event) {
                      return _vm.inputFile($event, "select", "featured_images")
                    },
                  },
                }),
              ]),
            ]
          ),
          _c("ValidateParts", {
            attrs: {
              value: _vm.value.featured_images,
              vid: "featured_images",
              rules: { required: _vm.isRequired },
              name: "メインビジュアル",
              errorDisplaySize: "text-xxs",
            },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "col-span-3" }, [
        _c("div", { staticClass: "movie-image-container w-full relative" }, [
          _vm.displayImage
            ? _c("img", {
                staticClass:
                  "w-full object-cover object-center absolute top-0 left-0 h-full",
                attrs: { src: _vm.displayImage },
              })
            : _c(
                "div",
                {
                  staticClass:
                    "w-full object-cover object-center absolute top-0 left-0 h-full bg-gray-100 table",
                },
                [
                  _c(
                    "p",
                    {
                      staticClass:
                        "text-center mx-auto h-full table-cell align-middle",
                    },
                    [_vm._v("画像比は16：9を推奨しています")]
                  ),
                ]
              ),
          _vm.displayImage
            ? _c(
                "button",
                {
                  staticClass:
                    "absolute bottom-0 right-0 select-main-btn mb-1 mr-1",
                  on: {
                    click: function ($event) {
                      return _vm.removeMainImage()
                    },
                  },
                },
                [
                  _c("img", {
                    staticClass: "w-5 h-5",
                    attrs: {
                      src: require("@/assets/image/trash_icon_grey.svg"),
                    },
                  }),
                ]
              )
            : _vm._e(),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "col-start-2 col-span-4" },
        [
          _c(
            "FlCheckbox",
            {
              model: {
                value: _vm.value.is_public,
                callback: function ($$v) {
                  _vm.$set(_vm.value, "is_public", $$v)
                },
                expression: "value.is_public",
              },
            },
            [_vm._v("一般公開を許可する")]
          ),
          _c("div", { staticClass: "rounded bg-gray-100 p-2 mt-2" }, [
            _vm._v(
              " 一般公開について、ご承認いただいた作品名は、オンラインフィルムマーケットのTOPページに掲載され、多くのバイヤーにキーアートと作品名をみていただけることで、販売率がUPいたします。 ※アカウントをもたれていないバイヤーは作品詳細やスクリーナーを見ることはできません。 "
            ),
          ]),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      { staticClass: "col-span-4 my-auto text-xs text-left col-start-2 -my-4" },
      [
        _c("span", { staticClass: "bg-off-yellow-lighter p-1 font-bold" }, [
          _vm._v("字幕情報登録には、「確定」ボタンを押す必要があります"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "w-5/12 mb-2 pl-1 -mt-1 mx-auto flex items-center justify-center",
      },
      [
        _c("img", {
          staticClass: "h-12 w-12",
          attrs: { src: require("@/assets/image/upload.svg") },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }